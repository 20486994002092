.foot{
    position: relative;
    bottom: 0px;
    left: 0px;
    background-color: rgb(54, 54, 54);
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 20px 0px 20px 0px;
    margin-top: 100px;
}

.foot a{
    color: white;
}

.head{
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1em;
    font-family: 'Courier New', Courier, monospace;
}

.head img{
    width: 100%;
    z-index: 1;
}