.body{
    z-index: 2;
    margin-top: 300px;
}

.textfield{
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border: solid 1px rgb(59, 59, 59);
    color: white;
    text-align: center;
    font-size: 2em;
    margin: 10px;
    border-radius: 30px;
}

.textfield:hover{
    animation: shine 2s forwards;
}

.button{
    background-color: black;
    padding: 20px;
    color: white;
    font-variant-caps: all-petite-caps;
    font-size: 2em;
    border-radius: 20px;
    border: solid white 1px;
}

.button:hover{
    cursor: pointer;
    animation: shine 2s forwards;
}

.checkbox{
    color: white;
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    margin: 20px;
}

@keyframes shine {
    0% {box-shadow: 0px 0px 0px white;}
    50% {box-shadow: 0px 0px 20px white;}
    100% {box-shadow: 0px 0px 0px white;}
}

@media only screen and (max-width: 800px) {

    .body{
        margin-top: 100px;
    }

    .textfield{
        font-size: 1em;
    }
}