.module{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.module{
    background-color: white;
    padding: 50px;
    border-radius: 20px;
}